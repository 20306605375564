body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

root {
  min-height: 100vh;
  background-color: #f7f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #f7f9fa;
}

html, body, #app, #app>div {
  min-height: 100vh;
  background-color: #f7f9fa;
}

.AnimatedGradientLoginBackground {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  margin-top: 0px;
  z-index: 2;
  text-align: center;
  background-image: linear-gradient(to right bottom, #3c80e7, #0f5fce, #164dac, #465bc2, #725ef3);
  background-size: 150% 150%;
  animation: AnimatedGradientLoginBackground 12s ease infinite;
}

@keyframes AnimatedGradientLoginBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
      transform: translateX(1);
  }

  25% {
      transform: translateX(-25%);
  }

  50% {
      transform: translateX(-50%);
  }

  75% {
      transform: translateX(-25%);
  }

  100% {
      transform: translateX(1);
  }
}

.PageBodyWide {
  display: flex;
  margin: auto;
  width: 85%;
  height: 100%;
  align-items: center; 
  justify-content: center;
}

.PageBody {
  display: flex;
  margin: auto;
  width: 60%;
  height: 100%;
  align-items: center; 
  justify-content: center;
}

.PageBodyElement {
  display: flex;
  justify-content: center;
  margin: 5px auto;
  padding: 5px 0;
}

.BodyElement {
  display: flex;
  margin: auto;
  width: 100%;
  align-items: center; 
  justify-content: center;
}

a {
  color: inherit;
  text-decoration: inherit;
}

h1 {
  margin: 0;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

h2 {
  margin: 0;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: rgb(51, 51, 51);
}

hr {
  border: 1px solid rgb(197, 197, 197);
  width: 100%;
}

div {
  justify-content: center;
}

img {
  border: 0;
}

Paper {
  border-radius: 5px;
}

.PageHeader {
  align-content: left;
  text-align: left;
  height: 55px;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1;
}

.PageFooter {
  align-content: center;
  text-align: center;
  height: 65px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  background-color: #FFFFFF;
  z-index: 1;
}

.loadingSpinner {
  border: 16px solid rgb(167, 167, 167);
  border-top: 16px solid #3498db;
  border-radius: 50%;
  margin: auto;
  width: 40px;
  height: 40px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}